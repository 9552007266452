<template>
  <div class="main">
    <div class="tit_copy demand_title">
      <span>需求管理</span>
      <demandApplyModal style="padding-bottom: 15px" />
    </div>
    <div class="niche_content_wrap" v-if="list.length > 0">
      <div class="demand_content" v-loading="loading">
        <div v-for="item in list" :key="item.key" class="demand_card_item">
          <div class="demand_card_status">
            <span class="demand_card_status_stage"
              >项目阶段：{{ item.project_phase_name }}</span
            >
            <span
              :style="tagStyle[item.project_status]"
              class="demand_card_status_step"
              >{{ tagStyle[item.project_status].text }}</span
            >
          </div>
          <div class="demand_card_info">
            <span>项目预算：</span>
            <span>{{ item.budget_range_name }}</span>
          </div>
          <div class="demand_card_info">
            <span>意向IP类型：</span>
            <span>{{ item.ip_type_name }}</span>
          </div>
          <div class="demand_card_info">
            <span>合作时间：</span>
            <span>{{ item.cooperation_month }}</span>
          </div>
          <div class="demand_card_del">
            <el-popconfirm title="是否将需求删除?" @confirm="delItem(item.id)">
              <template #reference>
                <span class="demand_card_del_btn"> 删除 </span>
              </template>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="demand_pagination">
        <el-pagination
          @current-change="changePage"
          hide-on-single-page
          background
          layout="prev, pager, next"
          :total="total"
        />
      </div>
    </div>
    <el-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import demandApplyModal from "@/views/home/demandApplyModal";
//project_status标签状态
// 1：待处理；2：进行中；98：已报名；99：已结束
export default {
  components: {
    demandApplyModal,
  },
  data() {
    return {
      page: 1,
      list: [],
      total: 0,
      loading: false,
      tagStyle: {
        1: {
          background: "rgba(245,99,11,0.08)",
          color: "#F5630B",
          text: "待处理",
        },
        2: {
          background: "rgba(1,187,134,0.08)",
          color: "#01BB86",
          text: "进行中",
        },
        99: {
          background: "rgba(106,112,132,0.08)",
          color: "#6A7084",
          text: "已结束",
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async delItem(id) {
      this.loading = true;
      try {
        this.API.demand_del({ id }, () => {
          ElMessage.success("删除成功！");
          this.getList();
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async getList() {
      this.loading = true;
      try {
        const query = {
          type: 1,
          pageSize: 10,
          page: this.page,
        };
        this.API.demand_list(query, (res) => {
          this.list = res.data.list;
          this.total = res.data.total;
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" type="text/css" scoped="">
.tit_copy {
  border-bottom: #d8d8d8 1px solid;
  > span {
    font-size: 14px;
    color: #222222;
    padding-bottom: 15px;
    line-height: 20px;
    display: inline-block;
    position: relative;
  }
  > span::before {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #f5630b;
  }
}

.main {
  flex: 1;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px;
}
.demand_title {
  display: flex;
  justify-content: space-between;
  .demand_btn {
    padding: 0 16px;
    height: 32px;
    background: #f5630b;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    line-height: 32px;
    margin-top: -10px;
  }
}
.demand_content {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 500px;
  .demand_card_item {
    height: 100%;
    width: 547px;
    border: 1px solid #e3e6ec;
    border-radius: 6px;
    padding: 24px 24px 16px;
    margin-bottom: 20px;
    .demand_card_status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .demand_card_status_stage {
        font-size: 16px;
        color: #0b0b0b;
        line-height: 22px;
      }
      .demand_card_status_step {
        width: 48px;
        height: 20px;
        background: rgba(1, 187, 134, 0.08);
        border-radius: 2px;
        font-size: 12px;
        color: #01bb86;
        line-height: 20px;
        text-align: center;
      }
    }
    .demand_card_info {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 12px;
      > span:first-child {
        color: #2e3036;
      }
      > span:last-child {
        color: #888888;
      }
    }
    .demand_card_del {
      display: flex;
      justify-content: flex-end;
      .demand_card_del_btn {
        width: 62px;
        height: 26px;
        border-radius: 4px;
        border: 1px solid rgba(158, 164, 186, 0.32);
        font-size: 14px;
        color: #585d70;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.demand_pagination {
  display: flex;
  justify-content: flex-end;
}
</style>
